<template>
  <title>番禺区教育局</title>
  <div style="height: 0" :class="{isActive: isActiveDiv}">
    <iframe
        :class="{isActive: isActive}"
        :src="link" frameborder="0" scrolling="no" id="bdIframe">
    </iframe>
  </div>
  <div v-if="!link" class="qrcode">
    <div class="can">
      <div class="codebody">
        <qrcode-vue v-if="value" :value="value" :size="250" level="H"/>
        <div class="text">
          <p class="title">使用番禺区评价量表系统扫描登录</p>
          <p class="desc">此次浏览有效期至:{{ end_time }} <span
              @click="refresh"
              class="refresh">刷新</span></p>
        </div>
      </div>
    </div>
    <div class="p">
      <p>有个鲸喜 &COPY; 2023</p>
      <p><a class="link" href="https://beian.miit.gov.cn">粤ICP备案2022053592号</a></p>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import axios from 'axios'

//字符串转换为base64

function toBase64(str) {
  return btoa(encodeURIComponent(str));
}

import cookie from 'vue-cookie'

let url = "/api/"
url = "https://pyqjyj.com/api/"

export default {
  name: 'App',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      value: "",
      link: '',
      end_time: '',
      height: 600,
      isActive: false
    };
  },
  beforeMount() {
    let qrcode = cookie.get('token');
    try {
      this.value = toBase64(qrcode)
      let d = qrcode.split(',')
      if (d[0] == '00000000000000000000' && d[2] == '1111111111') {
        let c = d[1].split('_')
        let id = c[0]
        let rand = c[1]
        this.check_id(id, rand)
        this.end_time = cookie.get('end_time');


      } else {
        throw new Error()
      }
    } catch (e) {
      this.refresh()

    }

  },
  methods: {
    check_id(id, rand) {
      axios.get(url + 'get_status', {
        params: {
          id: id, rand: rand
        }
      }).then(r => {
        console.log(id, r.data)
        if (r.data.status) {
          console.log('vue bi  开始渲染')

          // eslint-disable-next-line
          function messageListener(event) {
            console.log('vue bi ==>', event, event.data.height)

            if (event.data.height !== 'undefined' && event.data.height !== undefined && event.data.height) {
              console.log('vue setting', event, event.data.height)
              let testFrame = document.getElementById('bdIframe')
              testFrame.height = event.data.height;

              // testFrame.addEventListener('load', function() {
              //   // let iframe_head = testFrame.contentDocument.getElementsByClassName('iframe_head')[0]
              //   // iframe_head.style.display = 'none'
              //   console.log(testFrame,'FFF')

              }
            }

            // testFrame.addEventListener('load', function() {
            //   testFrame.height = this.height;
            // });
            //   // 使用postMessage传出的高度
            // if(event.data.height)
            // {
            //   this.height = event.data.height
            // }
            //   // 使用postMessage传出的仪表板页面ID
            //   console.log('vue bi Dashboard Id:', event.data.pageId);


          // 在仪表板加载之前进行监听
          window.addEventListener('message', messageListener);
          this.link = r.data.link
          this.isActive = true
          // 嵌入Quick BI仪表板的Iframe
          const iframe = document.getElementById('bdIframe')
          // 主动请求Quick BI仪表板高度
          // message传入的data中必须包含{ getDashboardHeight: true }
          iframe.contentWindow.postMessage({getDashboardHeight: true}, '*');

        } else {
          setTimeout(() => {
            this.check_id(id, rand)
          }, 2000)
        }
      })
    },
    refresh() {
      axios.get(url).then(r => {
        let {data} = r
        if (data.status) {
          let c = `00000000000000000000,${data.id}_${data.rand},1111111111`
          cookie.set("token", c, {expires: data.end_time});
          this.value = toBase64(c)
          console.log(this.value)
          this.end_time = data.end_time
          cookie.set("end_time", this.end_time, {expires: this.end_time});
          this.check_id(data.id, data.rand)
        }
      })
    },
  }
}
</script>

<style>
#app {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*margin-top: 60px;*/
}

.can {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.p {
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}

body {
  margin: 0;
}

.isActive {
  width: 100vw;
  min-height: 100vh;
}
.isActiveDiv{
  height: auto !important;
}

.codebody {
  box-sizing: border-box;
  padding-top: 100px;
  align-content: center;
  align-items: center;
  text-align: center;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background-color: #fff;
  width: 380px;
  height: 540px;
  box-shadow: 0 2px 10px #999;
  -moz-box-shadow: #999 0 2px 10px;
  -webkit-box-shadow: #999 0 2px 10px;
}

.title {
  text-align: center;
  font-size: 20px;
  color: #353535;
  margin-bottom: 20px
}

.desc {
  text-align: center;
  color: #a3a3a3;
  font-size: 15px;
  padding: 0 40px;
  line-height: 1.8;
}

.qrcode {
  background: url(bg.jpg) no-repeat 50%;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
}

.refresh {
  color: #42b983;
  text-decoration: underline;
  cursor: pointer;
}
.link{
  font-size: 12px;
  color: aquamarine;
}
</style>
